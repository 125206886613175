/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TermoDeUso } from "../core/model";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class PrivateService {
  private readonly privateUrl = `${environment.apiUrl}private`;
  private readonly http = inject(HttpClient);

  alterarSenha(senhaAtual: string, novaSenha: string): Observable<any> {
    return this.http.put(`${this.privateUrl}/alterarSenha`, [
      senhaAtual,
      novaSenha,
    ]);
  }

  getTermoVigente(): Observable<TermoDeUso> {
    return this.http.get<TermoDeUso>(`${this.privateUrl}/termoVigente`);
  }

  aceitarTermoVigente(idTermo: number): Observable<any> {
    return this.http.put(`${this.privateUrl}/aceitarTermo/${idTermo}`, null);
  }

  verificarTermoAssinado(): Observable<any> {
    return this.http.get(`${this.privateUrl}/ultimoTermoAssinado`);
  }

  hereIAm(): Observable<any> {
    return this.http.get(`${this.privateUrl}/hereIAm`);
  }

  getMeusDados(): Observable<any> {
    return this.http.get(`${this.privateUrl}/meusDados`);
  }

  alterarMeusDados(campo: string, novoValor: string): Observable<any> {
    return this.http.put(`${this.privateUrl}/meusDados`, [campo, novoValor]);
  }

  registrarAtividade(): Observable<any> {
    return this.http.get(
      `${this.privateUrl}/registraAtividade?userAgent=${navigator.userAgent}`,
    );
  }
}
